<template>
  <b-container class="bg-light">
    <b-row class="row text-white bg-kpmg-dark-blue py-4 mb-4">
      <b-col class="col">
        <h1 class="kstyle">adding ESG subscription</h1>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  computed: {
    message: {
      get () {
        return this.$store.state.message
      },
      set (payload) {
        this.$store.commit('setMessage', payload)
      }
    },
    user: {
      get () {
        return this.$store.state.user
      }
    }
  },
  created: function () {
    this.$stat.log({ page: 'getESG', action: 'open getESG' })
    this.message = {
      title: 'Setting up ESG subscription',
      body: 'We are setting up your ESG subscription'
    }
    this.$router.push({ name: 'Tag', params: { id: 1 }, query: { subscribe: 'true' } })
  }
}
</script>
